import { graphql, navigate } from 'gatsby';

/* eslint-disable import/exports-last */
import DynamicZone from '../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../components/floating-button/floating-button';
import Layout from '../components/layout';
import PageSEO from '../components/SEO/page-seo';
import React from 'react';

export const query = graphql`
    query ($slug: String!, $nextId: Int!, $prevId: Int!, $locale: String!) {
        cms {
            mediaContactTemplate(locale: $locale) {
                newMediaContact {
                    mediaContactEmails
                    mediaContactFollowUsLabel
                    mediaContactMediaKitLabel
                    mediaContactTitle
                    mediaContactDownloadables {
                    mediaContactDownloadableFile {
                        url
                        name
                    }
                    mediaContactDownloadableLabel
                    }
                    mediaContactSocialsLinks {
                    mediaContactSocialLink
                    mediaContactSocialLinkIcon {
                        url
                    }
                    }
                }
            }
            latestPosts: blogPosts(limit: 3, sort: "id:desc, sortDate:desc, published_at:desc", locale: $locale) {
                readLength
                date
                title
                slug
                mainImage {
                   url
                }
            }
            previousPost: blogPosts(where: { id: $prevId }, locale: $locale) {
                slug
            }
            nextPost: blogPosts(where: { id: $nextId }, locale: $locale) {
                slug
            }
            blogPosts(where: { slug: $slug }, locale: $locale) {
                __typename
                slug
                author {
                    bio
                    image {
                        url
                    }
                    nameAndSurname
                    title
                }
                content
                date
                mainImage {
                    url
                }
                readLength
                title
                seoTitle
                seoDescription
                seoLink
                seoImages {
                    url
                }
            }
        }
    }
`;

const BlogPost = (props) => {
    const { blogPosts, nextPost, previousPost, latestPosts } = props.data.cms;
    const mediaContact = props.data.cms.mediaContactTemplate;

    if (!blogPosts.length > 0) {
        navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        return null;
    }

    return (
        <Layout linksColor={`black`} location={props.location} locale={props.pageContext.locale}>
            <PageSEO
                link={blogPosts[0]?.seoLink}
                title={blogPosts[0]?.seoTitle}
                description={blogPosts[0]?.seoDescription}
                images={blogPosts[0]?.seoImages}
            />
            <FloatingButton />
            <DynamicZone
                mediaContact={mediaContact}
                components={blogPosts}
                nextPost={nextPost}
                previousPost={previousPost}
                latestPosts={latestPosts}
            />
        </Layout>
    );
};

export default BlogPost;
